import { atom } from 'recoil';

export const initialSlide = {
  panelId: 0,
  title: '',
  description: '',
  svg: [
    {
      id: 0,
      namedId: '',
      name: '',
      description: '',
      path: '',
    },
  ],
};

export const currentSlideState = atom({
  key: 'currentSlide', // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});

export const hoveredPathState = atom({
  key: 'hoveredSvg', // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});

export const selectedPathState = atom({
  key: 'selectedSvg', // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});

export const detailsContainerOpenState = atom({
  key: 'detailsContainerOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const isZoomedState = atom({
  key: 'isZoomed', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const isZoomingState = atom({
  key: 'isZooming', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const isPopupOpen = atom({
  key: 'openPopup', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const browserIsSafari = atom({
  key: 'isSafari',
  default: false,
});

export const browserIsFirefox = atom({
  key: 'isFirefox',
  default: false,
});
