import React, { ReactElement, useEffect, useRef } from 'react';

interface OutsideClickHandlerProps {
    onClickOutside: Function,
    children: ReactElement
}

export default function OutsideClickHandler(props: OutsideClickHandlerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { onClickOutside, children } = props;

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const { name } = event.target as HTMLButtonElement;
      // Run if click comes outside of current component and is not the details button on mobile or the see more/less buttons
      if (
        ref.current
        && !ref.current.contains(event.target as Node)
        && !document.getElementById('details-button')?.contains(event.target as Node)
        && (name !== 'description-button')
      ) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div ref={ref}>
      {children}
    </div>
  );
}
