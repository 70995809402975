import { SvgPath } from '../../Interfaces/PanelInterface';

const ZoomToPath = (svgPath: SvgPath, svgId: string) => {
  const svgElement = document.getElementById(svgId);
  const pathElement: SVGGraphicsElement = document.getElementById(svgPath.namedId) as any;
  const imageElement = svgElement!.firstChild as HTMLImageElement;

  if (svgElement && imageElement && pathElement) {
    let margin = 30;
    const width = 1736;
    const height = 3450;
    const bounds = pathElement.getBBox();

    // make margin bigger and outlines thinner for smaller paths.  functions as a max zoom
    if (bounds.width < 100 || bounds.height < 100) {
      margin = 80;
      pathElement.style.strokeWidth = '1';
    } else if (bounds.width < 300 || bounds.height < 300) {
      margin = 60;
      pathElement.style.strokeWidth = '2';
    } else if (bounds.width < 500 || bounds.height < 500) {
      margin = 40;
      pathElement.style.strokeWidth = '3';
    }

    const x0 = bounds.x - margin;
    const x1 = bounds.x + bounds.width + margin;
    const y0 = bounds.y - margin;
    const y1 = bounds.y + bounds.height + margin;

    const trans1 = [(width / 2), (height / 2)];
    const scale = 1 / Math.max((x1 - x0) / width, (y1 - y0) / height);
    const trans2 = [(-(x0 + x1) / 2), (-(y0 + y1) / 2)];

    pathElement.setAttribute('transform', `translate(${trans1[0]},${trans1[1]}) scale(${scale}) translate(${trans2[0]},${trans2[1]})`);
    imageElement.setAttribute('transform', `translate(${trans1[0]},${trans1[1]}) scale(${scale}) translate(${trans2[0]},${trans2[1]})`);
  }
};

const resetZoom = (svgId: string) => {
  const svgElement = document.getElementById(svgId);
  const imageElement = svgElement!.firstChild as HTMLImageElement;

  // Simply get rid of all properties we are using to zoom
  imageElement.removeAttribute('transform');
  svgElement!.querySelectorAll('g').forEach((g) => { g.removeAttribute('transform'); g.removeAttribute('style'); });
};

export {
  ZoomToPath,
  resetZoom,
};
