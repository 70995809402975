import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Components.css';
import {
  Avatar, ListItem, ListItemAvatar, ListItemText, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  currentSlideState, initialSlide, hoveredPathState, selectedPathState, detailsContainerOpenState, isZoomedState, isZoomingState,
  browserIsSafari, browserIsFirefox,
} from './Carousel/CarouselState';
import panels from '../static/panels.json';
import { Panel as PanelInterface, SvgPath } from '../Interfaces/PanelInterface';
import { resetZoom, ZoomToPath } from './Carousel/ZoomToPath';

const StyledButton = styled(Button)({
  textTransform: 'none',
  float: 'right',
  margin: '0rem 1rem 0.25rem 0.25rem',
});

function DescriptionPanel() {
  const currentSlide = useRecoilValue(currentSlideState);
  const isSafari = useRecoilValue(browserIsSafari);
  const isFirefox = useRecoilValue(browserIsFirefox);
  const [hoveredPath, setHoveredPath] = useRecoilState(hoveredPathState);
  const [selectedPath, setSelectedPath] = useRecoilState(selectedPathState);
  const [selectedSlide, setSelectedSlide] = useState<PanelInterface>(initialSlide);
  const [collapseDescription, setCollapseDescription] = useState(true);
  const [detailsContainerOpen, setDetailsContainerOpen] = useRecoilState(detailsContainerOpenState);
  const setIsZoomed = useSetRecoilState(isZoomedState);
  const setIsZooming = useSetRecoilState(isZoomingState);
  const [isPathDescriptionExpanded, setIsPathDescriptionExpanded] = useState(Array(selectedSlide.svg.length));
  const [currentPanel, setPanel] = useState(0);
  const svgPathCol = document.getElementById('svgPathCol');

  useEffect(() => {
    setSelectedSlide(panels[currentSlide]); // variable index value we can reference later
  }, [currentSlide]);

  let itemHoverClass = '';
  let itemSelectClass = '';

  // Gets first 16 words of the description
  const shortenedDescription = selectedSlide.description.match(/(^(?:\S+\s+\n?){1,16})/);

  function selectPath(e: React.MouseEvent<HTMLLIElement, MouseEvent>, slice: SvgPath) {
    const { name } = e.target as HTMLButtonElement;
    if (name !== 'description-button') {
      const targetId = parseInt(e.currentTarget.id.replace(/^\D+/g, ''), 10);
      if (targetId === selectedPath) { // if target is already selected, unselect it
        setIsZooming(true);
        setSelectedPath(0);
        resetZoom(selectedSlide.title);
        // Transition/animation takes 1.5 secs. Extra .1 prevents it from jumping
        // On Safari and Firefox the cool animation doesn't work, so we set a tiny timeout to make it look better.
        setTimeout(() => { setIsZooming(false); }, isSafari || isFirefox ? 10 : 1600);
        setIsZoomed(false);
      } else {
        setIsZooming(true);
        ZoomToPath(slice, selectedSlide.title);
        // Transition/animation takes 1.5 secs. Extra .1 prevents it from jumping
        // On Safari and Firefox the cool animation doesn't work, so we set a tiny timeout to make it look better.
        setTimeout(() => { setIsZooming(false); }, isSafari || isFirefox ? 10 : 1600);
        setIsZoomed(true);
        setSelectedPath(slice.id);
      }
    }
  }

  // resets "See More" buttons on page on panel change
  if (currentSlide !== currentPanel) {
    setIsPathDescriptionExpanded(Array(selectedSlide.svg.length));
    setPanel(currentSlide);
    setCollapseDescription(true);
  }

  // This function handles the description length for the path (See More/See Less, etc)
  function itemDescription(fullItemDescript: string, itemID: number) {
    if (fullItemDescript.length <= 100) { // show whole desc if not super long
      return <span>{fullItemDescript}</span>;
    }
    // gets first 15 words
    const shortItemDescript = fullItemDescript.match(/(^(?:\S+\s+?){1,15})/);
    if (shortItemDescript) {
      const shortItemDescriptStr = `${shortItemDescript[0]}...`;

      return (isPathDescriptionExpanded[itemID] && shortItemDescriptStr)
        ? (
          <span>
            {fullItemDescript}
            {' '}
            <StyledButton
              variant="text"
              size="small"
              name="description-button"
              onClick={() => { const newArray = [...isPathDescriptionExpanded]; newArray[itemID] = false; setIsPathDescriptionExpanded(newArray); }}
            >
              See less
            </StyledButton>
          </span>
        )
        : (
          <span>
            {shortItemDescriptStr}
            {' '}
            <StyledButton
              variant="text"
              size="small"
              name="description-button"
              onClick={() => { const newArray = [...isPathDescriptionExpanded]; newArray[itemID] = true; setIsPathDescriptionExpanded(newArray); }}
            >
              See more
            </StyledButton>
          </span>
        );
    }
    return <span>{fullItemDescript}</span>;
  }

  function CenterToPath(svgPath: SvgPath) {
    const pathElement: SVGGraphicsElement = document.getElementById(svgPath.namedId) as any;

    if (pathElement) {
      let stroke = 15;
      let margin = 80;
      const width = 1736;
      const height = 3450;
      const bounds = pathElement.getBBox();

      // make outlines thinner for smaller paths.  functions as a max zoom
      if (bounds.width < 100 || bounds.height < 100) {
        stroke = 9;
      } else if (bounds.width < 300 || bounds.height < 300) {
        stroke = 11;
      } else if (bounds.width < 500 || bounds.height < 500) {
        stroke = 12;
      }

      // make margin bigger for bigger paths
      if (bounds.width > 500 || bounds.height > 500) {
        margin = 180;
      }

      const x0 = bounds.x - margin;
      const x1 = bounds.x + bounds.width + margin;
      const y0 = bounds.y - margin;
      const y1 = bounds.y + bounds.height + margin;

      const trans1 = [(width / 2), (height / 2)];
      const scale = 1 / Math.max((x1 - x0) / width, (y1 - y0) / height);
      const trans2 = [(-(x0 + x1) / 2), (-(y0 + y1) / 2)];

      const transform = `translate(${trans1[0]},${trans1[1]}) scale(${scale}) translate(${trans2[0]},${trans2[1]})`;

      return { transform, stroke };
    }
  }

  if (detailsContainerOpen && svgPathCol) {
    svgPathCol.style.height = '40vh';
  } else if (svgPathCol) {
    svgPathCol.style.height = '3.75vh';
  }

  return (
    <>
      <div
        onClick={() => setDetailsContainerOpen(!detailsContainerOpen)}
        onKeyPress={() => setDetailsContainerOpen(!detailsContainerOpen)}
        role="button"
        tabIndex={0}
        className="details-text"
        id="details-button"
      >
        <span>
          {detailsContainerOpen
            ? <>&#8595;</>
            : <>&#8593;</>}
          <span className="p-1">Details</span>
        </span>
      </div>

      <div className="description-container" id="descriptionContainer">
        <div className="selector-header">
          <h3 className="text-center panel-title">{selectedSlide.title}</h3>
          {(selectedSlide.description !== '') && (
            (collapseDescription && shortenedDescription) // need to make sure regex match is not null for typescript.
              ? (
                <>
                  <hr className="description-separator" />
                  <p className="panel-description">
                    {`${shortenedDescription[0]}...`}
                    {' '}
                    <StyledButton
                      name="description-button"
                      variant="text"
                      size="small"
                      onClick={() => setCollapseDescription(!collapseDescription)}
                    >
                      See more
                    </StyledButton>
                  </p>
                </>
              )
              : (
                <>
                  <hr className="description-separator" />
                  <p className="p-3 panel-description">
                    {selectedSlide.description}
                    {' '}
                    <StyledButton
                      name="description-button"
                      variant="text"
                      size="small"
                      onClick={() => setCollapseDescription(!collapseDescription)}
                    >
                      See less
                    </StyledButton>
                  </p>
                </>
              )
          )}
        </div>
        <div data-tour="step-5" className="list-container" id="listContainer">
          {selectedSlide.svg.map((slice) => {
            // highlighting the current item if hovered
            (hoveredPath === slice.id) ? itemHoverClass = 'item-hovered' : itemHoverClass = '';
            // highlighting the current item if selected
            (selectedPath === slice.id) ? itemSelectClass = 'item-selected' : itemSelectClass = '';

            const pathCss = CenterToPath(slice);

            return (
              <ListItem
                key={slice.id}
                id={`${`listItem${slice.id}`}`}
                className={`${itemHoverClass} ${itemSelectClass} list-item-container`}
                onMouseEnter={() => setHoveredPath(slice.id)}
                onMouseLeave={() => setHoveredPath(0)}
                onClick={(e) => selectPath(e, slice)}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: '#d9c5b250', width: 50, height: 50 }}>
                    {/* On Safari, the avatar paths don't work for some reason. After hours of trying to fix it, we added a plus icon instead */}
                    {isSafari ? (<AddRoundedIcon style={{ color: '#d15b38' }} />) : (
                      <svg viewBox="0 0 1736 3450" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <path className="avatar" d={slice.path} transform={pathCss?.transform} style={{ strokeWidth: pathCss?.stroke }} />
                      </svg>
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className="list-item-text"
                  primary={slice.name}
                  secondary={itemDescription(slice.description, slice.id)}
                />
              </ListItem>
            );
          })}

        </div>
      </div>
    </>
  );
}

export default DescriptionPanel;
