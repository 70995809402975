import React, { } from 'react';
import { CarouselProvider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../Components.css';
import CarouselContent from './CarouselContent';

function Carousel() {
  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={7}
      step={1}
      naturalSlideWidth={1736}
      naturalSlideHeight={3450}
      infinite
      dragEnabled={false} // needs to be false so it doesn't conflict with zooming
      touchEnabled={false}
    >
      <CarouselContent />
    </CarouselProvider>
  );
}

export default Carousel;
