import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Grid } from '@mui/material';
import Carousel from './Carousel/Carousel';
import DescriptionPanel from './DescriptionPanel';
import './Components.css';
import { browserIsSafari, browserIsFirefox } from './Carousel/CarouselState';

function TourContainer() {
  const setIsSafari = useSetRecoilState(browserIsSafari);
  const setIsFirefox = useSetRecoilState(browserIsFirefox);

  useEffect(() => {
    // Detects if user browser is Safari
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      setIsSafari(true);
    }
    // Detects if user browser is Firefox
    if (navigator.userAgent.match(/firefox|fxios/i)) {
      setIsFirefox(true);
    }
  }, []);

  return (
    <Grid data-tour="step-7" className="container-grid" container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
      <Grid item xs={12} sm={12} md={9} justifyContent="center" className="carousel-container">
        <Carousel />
      </Grid>
      <Grid item xs={12} sm={12} md={3} className="svg-path-col" id="svgPathCol">
        <DescriptionPanel />
      </Grid>
    </Grid>
  );
}

export default TourContainer;
