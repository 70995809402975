import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../Components.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  currentSlideState, hoveredPathState, selectedPathState, detailsContainerOpenState, isZoomedState, isZoomingState,
} from './CarouselState';
import { Panel } from '../../Interfaces/PanelInterface';
import OutsideClickHandler from '../OutsideClickHandler';
import { resetZoom } from './ZoomToPath';

export interface SvgOverlayProps {
    selectedSlide: Panel;
}

function SvgOverlay(props: SvgOverlayProps) {
  const [detailsContainerOpen, setDetailsContainerOpen] = useRecoilState(detailsContainerOpenState);
  const [hoveredPath, setHoveredPath] = useRecoilState(hoveredPathState);
  const [selectedPath, setSelectedPath] = useRecoilState(selectedPathState);
  const currentSlide = useRecoilValue(currentSlideState);
  const [isZoomed, setIsZoomed] = useRecoilState(isZoomedState);
  const [isZooming, setIsZooming] = useRecoilState(isZoomingState);

  const { selectedSlide } = props;
  let hoverClass = '';
  let selectClass = '';
  let zoomClass = '';

  function selectPath(e: React.MouseEvent<SVGGElement, MouseEvent>, id: number) {
    if (!isZoomed) {
      const targetId = parseInt(e.currentTarget.id.replace(/^\D+/g, ''), 10);
      if (targetId === selectedPath) { // if target is already selected, unselect it
        setSelectedPath(0);
        setDetailsContainerOpen(false);
      } else {
        setSelectedPath(id);
        setDetailsContainerOpen(true);
      }

      // code below is for automatic scroll to element on path select
      const elem = document.getElementById(`${`listItem${id}`}`);
      elem?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  function zoomOut(resetTransform: Function) {
    setIsZooming(true);
    resetTransform();
    resetZoom(selectedSlide.title);
    // Transition/animation takes 1.5 secs. Extra .1 prevents it from jumping
    setTimeout(() => { setIsZooming(false); }, 1600);
    setIsZoomed(false);
  }

  function deselect() {
    if (detailsContainerOpen && selectedPath) {
      setSelectedPath(0);
      setDetailsContainerOpen(false);
    }
  }

  if (currentSlide === selectedSlide.panelId) {
    return ( // This TransformWrapper and accompanying Component handle the zooming/panning.
      <TransformWrapper>
        {({ resetTransform }) => (
          <OutsideClickHandler onClickOutside={() => { zoomOut(resetTransform); }}>
            <TransformComponent>
              <svg
                id={selectedSlide.title}
                className="slider-img svg"
                viewBox="0 0 1736 3450"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <image
                  className="svg-image"
                  width="1736"
                  xlinkHref={`./panelImages/${selectedSlide.title}.png`}
                  onClick={() => deselect()}
                />

                {selectedSlide.svg.map((slice) => {
                  // highlighting the current path if hovered
                  (hoveredPath === slice.id && !isZoomed && !isZooming) ? hoverClass = 'path-hovered' : hoverClass = 'path-no-hovered';
                  // highlighting the current path if selected
                  (selectedPath === slice.id && !isZoomed && !isZooming) ? selectClass = 'path-selected' : selectClass = '';
                  // smaller stroke if zoomed in
                  (selectedPath === slice.id && isZoomed && !isZooming) ? zoomClass = 'path-zoomed' : zoomClass = '';

                  return (
                    <g
                      className={`${hoverClass} ${selectClass} ${zoomClass} path`}
                      key={slice.id}
                      id={slice.namedId}
                      onMouseEnter={() => setHoveredPath(slice.id)}
                      onMouseLeave={() => setHoveredPath(0)}
                      onClick={(e) => selectPath(e, slice.id)}
                    >
                      <path d={slice.path} />
                    </g>
                  );
                })}
              </svg>
            </TransformComponent>
          </OutsideClickHandler>
        )}
      </TransformWrapper>
    );
  }
  return (
    <img
      src={`./panelImages/${selectedSlide.title}.png`}
      alt={`${selectedSlide.title}.png`}
      title={`${selectedSlide.title}.png`}
      className="slider-img svg slider-img-hidden"
    />
  );
}

export default SvgOverlay;
