import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { TourProvider } from '@reactour/tour';
import steps from './Components/steps';
import TourContainer from './Components/TourContainer';

const theme = createTheme();

export default function App() {
  return (
    <RecoilRoot>
      <TourProvider steps={steps}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<TourContainer />} />
          </Routes>
        </ThemeProvider>
      </TourProvider>
    </RecoilRoot>
  );
}
