import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { isMobile } from 'react-device-detect';
import { useTour } from '@reactour/tour';
import { isPopupOpen } from './Carousel/CarouselState';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props: any) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        />
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function Popup() {
  const [isOpen, setOpen] = useRecoilState(isPopupOpen);
  const { setIsOpen } = useTour();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const openTour = () => {
    setIsOpen(true);
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle onClose={handleClose}>
          7 Pillars of Humanity Virtual Tour
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" gutterBottom>
            Why the 7 Pillars of Humanity?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Created as thesis artwork for the Roots of Humanity structure, these 7 stained glass panels illustrate seven key,
            noble characteristics that humans are capable of: Knowledge, Creativity, Faith, Love, Unity, Freedom, and Courage.
            Examples from across the world are included in each scene. This artwork will travel to locations around the globe
            to inspire and unify individuals everywhere.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Root&apos;s of Humanity&apos;s Mission:
          </Typography>
          <Typography variant="body2" gutterBottom>
            Roots of Humanity works through art and education to foster esteem, connection, and creativity within individuals
            while unifying and inspiring communities and humanity.
            {' '}
            <a href="https://www.rootsofhumanity.org" target="blank">Learn More about ROH</a>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Note: For the best experience, we recommend viewing this tour using Chrome
          </Typography>
        </DialogContent>
        <DialogActions>
          {!isMobile && <Button autoFocus onClick={openTour}>Site Walkthrough</Button>}
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
