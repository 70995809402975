import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import {
  ButtonBack, Slide, Slider, CarouselContext, Dot, ButtonNext,
} from 'pure-react-carousel';
import { Button } from '@mui/material';
import { useTour } from '@reactour/tour';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  currentSlideState,
  isZoomedState,
  isPopupOpen,
} from './CarouselState';
import SvgOverlay from './SvgOverlay';
import ErrorBoundary from '../ErrorBoundary';
import '../Components.css';
import Popup from '../Popup';
import panels from '../../static/panels.json';

function CarouselContent() {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useRecoilState(currentSlideState);
  const isZoomed = useRecoilValue(isZoomedState);
  const [expanded, setExpanded] = useState<any>(false);
  const setOpen = useSetRecoilState(isPopupOpen);
  const { isOpen } = useTour();

  let active = '';

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, setCurrentSlide]);

  // create an Observer instance to dynamically change carousel width
  const resizeObserver = new ResizeObserver((entries) => {
    const slider = document.getElementById('sliderContainer');
    if (slider) {
      slider.style.width = `${entries[0].target.clientHeight * 1}px`;
    }
  });
  resizeObserver.observe(document.body);

  const openPopup = () => {
    if (!isOpen) {
      setOpen(true);
    }
  };

  const scrollToTop = () => {
    let elem = document.getElementById('descriptionContainer');
    elem?.scroll({ top: 0, left: 0, behavior: 'smooth' });
    elem = document.getElementById('listContainer');
    elem?.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Navbar data-tour="step-3" expand="lg" className="carousel-nav justify-content-center" expanded={expanded} variant="dark">
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : 'expanded')}
          aria-controls="basic-navbar-nav"
          className="panel-toggle-box"
          id="panelToggle"
        />
        <span className="navbar-brand panel-nav-toggle-name" style={{ fontSize: '18px' }}>{panels[currentSlide].title}</span>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto justify-content-center panel-nav" id="panelNav">
            {panels.map((panel, count) => {
              (panel.panelId === currentSlide) ? active = 'dot-btn-active' : active = '';
              return (
                <NavItem key={panel.panelId} onClick={() => { scrollToTop(); setTimeout(() => { setExpanded(false); }, 75); }}>
                  <Dot key={panel.panelId} slide={count} className={`dot-btn ${active}`}>{panel.title}</Dot>
                </NavItem>
              );
            })}
            <Popup />
          </Nav>
        </Navbar.Collapse>
        <Button data-tour="step-6">
          <InfoOutlinedIcon className="info-icon" onClick={openPopup} />
        </Button>
      </Navbar>

      <div className="carousel-grid">
        <div className="slider-btn-col">
          <ButtonBack data-tour="step-1" className="slider-btn slider-btn-back" onClick={scrollToTop}>&#8249;</ButtonBack>
        </div>
        <div className="slider-col zoom">
          <Slider data-tour="step-4" className="slider" id="sliderContainer">
            {panels.map((panel) => (
              <Slide key={panel.panelId} index={panel.panelId}>
                <ErrorBoundary>
                  <SvgOverlay selectedSlide={panel} />
                </ErrorBoundary>
              </Slide>
            ))}
          </Slider>
          {(isZoomed)
            && <Button className="reset-zoom-button" variant="outlined" size="small">Reset Zoom</Button>}
        </div>
        <div className="slider-btn-col">
          <ButtonNext data-tour="step-2" className="slider-btn slider-btn-next" onClick={scrollToTop}>&#8250;</ButtonNext>
        </div>
      </div>
    </>
  );
}

export default CarouselContent;
