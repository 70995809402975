/* eslint-disable max-len */

const steps = [
  {
    selector: '[data-tour="step-1"]',
    content: 'Use this button to navigate to the previous panel',
  },
  {
    selector: '[data-tour="step-2"]',
    content: 'Use this button to navigate to the next panel',
  },
  {
    selector: '[data-tour="step-3"]',
    content: 'Use these buttons to navigate between each panel',
  },
  {
    selector: '[data-tour="step-4"]',
    content: 'As you hover over the center image with the mouse, you will notice specific sections of the image will be highlighted. Click on a highlighted section to view more info to the right.',
  },
  {
    selector: '[data-tour="step-5"]',
    content: 'Here is a list of art pieces contained in the stained art glass. Click one to highlight and zoom to it on the left.',
  },
  {
    selector: '[data-tour="step-6"]',
    content: 'After exiting the walkthrough, you can open the dialog box anytime by clicking this icon',
  },
  {
    selector: '[data-tour="step-7"]',
    content: 'Enjoy exploring these pieces of stained glass art! You can close the walkthrough clicking the X in the top left corner of this dialog box.',
  },
];

export default steps;
